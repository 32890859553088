<template lang="pug">
.min-h-screen
  .container.mt-10.mb-16
    .w-full
      .max-w-lg.mx-auto.mb-4
        baseH1Pages.mb-4
          .text-4xl Plantillas de curriculum profesionales
        p.text-center.text-sm Las siguientes plantillas para curriculum han sido diseñadas especialmente para conseguir seguir las mejores impresiones en tu busqueda de empleo
      .box-group-buttons
        baseButton 
</template>
<script>
export default {
  name: "SelectTemplate",
};
</script>

<style lang="scss" scoped></style>
